@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
    --purple: hsl(240, 80%, 89%);
    --pink: hsl(0, 59%, 94%);
    --light-bg: hsl(204, 37%, 92%);
    --light-gray-bg: hsl(0, 0%, 94%);
    --white: hsl(0, 0%, 100%);
    --dark: hsl(0, 0%, 7%);
    --text-gray: hsl(0, 0%, 30%);
}

body {
    background: var(--light-bg);
    font-family: "Space Grotesk", sans-serif;
    color: var(--dark);
}