html {
    scroll-behavior: smooth;
}


/* VERTICAL MOVEMENT ANIMATION */

.vert-move {
    -webkit-animation: mover 1s infinite alternate;
    animation: mover 1s infinite alternate;
}

.vert-move1 {
    -webkit-animation: mover 5s infinite alternate;
    animation: mover 5s infinite alternate;
}

.vert-move2 {
    -webkit-animation: mover 7s infinite alternate;
    animation: mover 7s infinite alternate;
}

.vert-move3 {
    -webkit-animation: mover 2s infinite alternate;
    animation: mover 2s infinite alternate;
}

img.vert-move {
    -webkit-animation: mover 3s infinite alternate;
    animation: mover 3s infinite alternate;
}

@-webkit-keyframes mover {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-50px);
    }
}

@keyframes mover {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-50px);
    }
}


/* TRANSITION GROW */

.grow:hover {
    transform: scale(1.2);
}

.grow2:hover {
    transform: scale(1.05);
}

.transition {
    transition: all 0.3s ease-in-out;
}


/* POP-OUT ANIMATION*/

.animate {
    animation-duration: 0.5s;
    animation-name: animate-fade;
    animation-delay: 0.5s;
    animation-fill-mode: backwards;
}

@keyframes animate-fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.animate.pop {
    animation-duration: 0.5s;
    animation-name: animate-pop;
    animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
}

@keyframes animate-pop {
    0% {
        opacity: 0;
        transform: scale(0.5, 0.5);
    }
    100% {
        opacity: 1;
        transform: scale(1, 1);
    }
}

.delay {
    animation-delay: 0.7s;
}

@media screen and (prefers-reduced-motion: reduce) {
    .animate {
        animation: none !important;
    }
}


/* NAVBAR RESPONSIVENESS */

.nav>.nav-btn {
    display: none;
}

.nav>#nav-check {
    display: none;
}

@media (max-width: 600px) {
    .nav>.nav-btn {
        display: inline-block;
        position: absolute;
        right: 0.2rem;
        top: 1.2rem;
    }
    .nav>.nav-btn>label {
        display: inline-block;
        width: 50px;
        height: 50px;
    }
    .nav>.nav-btn>label>span {
        display: block;
        width: 25px;
        height: 10px;
        border-top: 2px solid #000;
    }
    .nav>.nav-links {
        position: absolute;
        display: block;
        width: 100%;
        background-color: #fff;
        height: 0px;
        transition: all 0.3s ease-in;
        overflow-y: hidden;
        top: 64px;
        left: 0px;
    }
    .nav>.nav-links>a {
        display: block;
        width: 100%;
    }
    .nav>#nav-check:not(:checked)~.nav-links {
        height: 0px;
    }
    .nav>#nav-check:checked~.nav-links {
        height: calc(100vh - 50px);
        overflow-y: auto;
    }
}