.sidebar>a>img {
    width: 100%;
    transition: all 0.5s;
}

.sidebar>a>img:hover {
    filter: drop-shadow(0 0 10px skyblue);
}

.sidebar a {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.493);
    font: 200 1rem "Roboto";
    padding: 2rem;
    transition: all 0.5s;
}

.sidebar a:hover {
    color: skyblue;
    transform: scale(1.1);
}

.sidebar a>P {
    display: flex;
    align-items: center;
}

.sidebar a>p>svg {
    margin-right: 0.5rem;
}

.MuiTypography-root {
    background-color: #fff !important;
}